<template>
  <Dialog
    :show="show"
    @close="$emit('close')"
    :background-image="require('@/assets/img/dialog-bg-3.png')"
  >
    <div class="congrats-wrapper">
      <div class="title">Code Verification</div>

      <div class="content">
        <div class="ui mini action input">
          <input
            type="text"
            placeholder="Enter your TEST code"
            v-model="code"
          />
          <button class="ui button" @click="check">Check</button>
        </div>
        <div class="congrats-dialog-footer">
          <div v-if="error" class="result">
            <i class="ui icon exclamation triangle large red"></i>
            <br />
            You've made 3 typing errors.<br />
            Please try again after 1 hours
          </div>
          <div v-if="valid" class="result">
            <i class="ui icon check circle large outline green"></i>
            <br />
            Your test code is valid.
          </div>
          <div v-if="invalid" class="result">
            <i class="ui icon exclamation triangle large red"></i>
            <br />
            Your test code is invalid.
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, watch, nextTick, toRef, toRefs } from 'vue';
import Dialog from '@/components/Dialog.vue';
import useModal from '@/store/modules/modal';
import http from '@/utils/http.js';
const emit = defineEmits(['close']);
const modal = useModal();

const error = ref(false);
const valid = ref(false);
const invalid = ref(false);
const code = ref('');

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
});
const { show } = toRefs(props);
watch(
  () => modal.conent,
  (old, v) => {
    $('.ui.popup-tarot').popup({
      inline: true,
    });
  }
);

const check = () => {
  if (code.value.trim() == '') return false;

  error.value = false;
  valid.value = false;
  invalid.value = false;

  http
    .post('/api/user/checkTestCode', { code: code.value.trim() })
    .then(function (res) {
      if (!res) return false;

      error.value = res.data.status.code == 10500;
      valid.value = res.data.status.code == 10200;
      invalid.value = res.data.status.code == 10403;
    });
};
</script>

<style lang="less" scoped>
.m-view-congrats {
  width: 100vw;
  height: 100%;
  background-image: url('@/assets/img/dialog-bg.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.congrats-wrapper {
  width: 574px;
  padding: 40px 30px 30px 30px;
  box-sizing: content-box;
  .title {
    font-size: 30px;
    font-weight: 400;
    color: #d8d5d0;
    background: linear-gradient(0deg, #f9f6c3 0%, #e7a952 100%);
    margin-bottom: 38px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 1rem;
    line-height: 1;
  }
  .content {
    line-height: 30px;
    font-family: auto;
    font-weight: 500;
    color: #d8d5d0;
    font-size: 22px;
    text-align: center;
    margin-bottom: 12px;
  }
  .ui.input > input {
    color: rgba(255, 255, 255, 0.85);
    background: #343434;
    border-radius: 0;
  }
  .ui.input > input:focus {
    color: rgba(255, 255, 255, 0.85);
    border-color: #85b7d9;
    background: #343434;
    border-radius: 0;
    box-shadow: 0;
  }
  .result {
    display: block;
    font-family: auto;
  }
  .ui.button {
    background: #b7afa4;
    border-radius: 0 !important;
  }
}
.congrats-dialog-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  min-height: 65px;
  .van-button {
    width: 241px;
    font-size: 20px;
    padding: 0;
  }
}
</style>
