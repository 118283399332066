import { defineStore } from "pinia";

const useModal = defineStore("modal", {
  persist: true,
  state: () => ({
      title: '',
      conent: '',
      align: 'center'
  }),
  getters: {},
  actions: {},
});

export default useModal;
