import { ref } from "vue"

const walletVisible = ref(false)
const loginVisible = ref(false)
const disabledWallet = ref('off')

export const useControlLoginDialog = () => {

  const setVisible = (bol) => {
    loginVisible.value = bol
  }

  return {
    setVisible,
    loginVisible
  }
}

export const useControlWalletDialog = (resetDisable = true) => {
  if (resetDisable) disabledWallet.value = 'off'
  /**
   * @param {boolean} bol 是否开启钱包弹窗
   * @param {string} disabled 是否禁用某个钱包连接 off 不禁用，aptos 禁用 aptos, eth 禁用 eth
   */
  const setWalletVisible = (bol, disabled = 'off') => {
    walletVisible.value = bol
    disabledWallet.value = disabled
  }

  return {
    setWalletVisible,
    walletVisible,
    disabledWallet
  }
}