<template>
  <div>
    <div class="center">
      <Transition>
        <div id="hintTextCtn" class="ShowHint" v-if="showSelectHint">
          <vuetyped
            :strings="['CHOOSE YOUR CARD TO OPEN']"
            :loop="false"
            :smart-backspace="false"
            :typeSpeed="8"
            :showCursor="false"
            @onComplete="showSelectHintFin = true"
          >
            <div class="typing"></div>
          </vuetyped>
        </div>
      </Transition>
    </div>
    <div id="container" class="cardField"></div>
    <!-- <van-button id="againbtn" class="btnbar" @click="handleAgain">Again</van-button> -->
  </div>
</template>

<script setup>
import { Deck } from '@/utils/deck.js';
import '@/utils/deck.css';
import { onMounted, ref } from 'vue';
const emit = defineEmits(['selected', 'again']);
var easing = Deck.easing;
var prefix = Deck.prefix;

var transform = prefix('transform');
var transition = prefix('transition');
var transitionDelay = prefix('transitionDelay');
var boxShadow = prefix('boxShadow');

var translate = Deck.translate;
var curDeck = null;

const showSelectHint = ref(true);
const showSelectHintFin = ref(false);

onMounted(() => {
  var $container = document.getElementById('container');
  //var $aBtnRoot = document.getElementById("againbtn");
  // var $topbar = document.getElementById("topbar");

  //var $fan = document.createElement("AgainButton");
  //$fan.textContent = "Again";

  // $topbar.appendChild($fan);

  var deck = Deck();
  curDeck = deck;
  Deck.curDeck = curDeck;
  Deck.ChooseCardsFin = ChooseCardsFin;
  Deck.CallCardAgain = handleAgain;

  deck.cards.forEach(function (card, i) {
    card.enableMoving();

    /*
    card.$el.addEventListener("click", onTouch);
    card.$el.addEventListener("touchstart", onTouch);

    function onTouch(e) {
      //选择卡片事件
      if (e.target.parentElement?.classList?.value.includes("selected")) {
        if (Deck.ClickSelectedCard == true) {
          var tense = "present";
          if (card.isSelect == 1) {
            tense = "past";
          } else if (card.isSelect == 3) {
            tense = "future";
          }

          var cardName = Deck.CardNameList[card.value - 1];
          var posName = "upright";
          if (card.isReverse == true) {
            posName = "reversed";
          }

          //console.log(tense + '   ' + cardName + '   ' + posName);

          //emit("selected", e.target.parentElement);
          emit("selected", tense, cards, posName);
        }
      }
    }
	*/
  });

  setTimeout(() => {
    Deck.selectCardNum = 0;
    deck.fan();
  }, 4);
  // $fan.addEventListener("click", function () {
  //   Deck.selectCardNum = 0;
  //   deck.fan();
  // });

  deck.mount($container);
  var cbH = document.body.clientHeight;
  var tranX = 0;
  var tranY = cbH * 0.5 - cbH * 0.3;
  //$aBtnRoot.style[transform] = "translateY(" + tranY + "px)";
  //"translateY(-100px)" + "translateX(-100px)";

  var $hintText = document.getElementById('hintTextCtn');
  $hintText.style[transform] =
    'translateX(-50%) translateY(' + Deck.hintTextY + 'px)';
});

//deck.intro()
//deck.sort()

const handleAgain = () => {
  emit('again');
  Deck.selectCardNum = 0;
  Deck.ClickSelectedCard = false;
  Deck.ChooseCards = [null, null, null];
  //Deck.CSelectedCardName = "";
  curDeck.fan();
  // Deck.selectCardNum = 0;
  // curDeck.fan();
};

const GetPosName = function (isRe) {
  var posName = 'upright';
  if (isRe == true) {
    posName = 'reversed';
  }

  return posName;
};

const ChooseCardsFin = function (card1, card2, card3) {
  var card1Name = Deck.CardNameList[card1.value - 1];
  var card1PosName = GetPosName(card1.isReverse);

  var card2Name = Deck.CardNameList[card2.value - 1];
  var card2PosName = GetPosName(card2.isReverse);

  var card3Name = Deck.CardNameList[card3.value - 1];
  var card3PosName = GetPosName(card3.isReverse);

  var cards = {
    past: {
      name: card1Name,
      pos: card1PosName,
    },
    present: {
      name: card2Name,
      pos: card2PosName,
    },
    future: {
      name: card3Name,
      pos: card3PosName,
    },
  };

  //console.log('Past: ' + card1Name + '   ' + card1PosName);
  //console.log('Present: ' + card2Name + '   ' + card2PosName);
  //console.log('Future: ' + card3Name + '   ' + card3PosName);

  emit('selected', cards);
};

function printMessage(text) {
  var $message = document.createElement('p');
  $message.classList.add('message');
  $message.textContent = text;

  document.body.appendChild($message);

  $message.style[transform] = translate(window.innerWidth + 'px', 0);

  setTimeout(function () {
    $message.style[transition] = 'all .7s ' + easing('cubicInOut');
    $message.style[transform] = translate(0, 0);
  }, 1000);

  setTimeout(function () {
    $message.style[transform] = translate(-window.innerWidth + 'px', 0);
  }, 6000);

  setTimeout(function () {
    document.body.removeChild($message);
  }, 7000);
}
</script>

<style lang="less" scoped>
.ShowHint {
  position: absolute;
  transform: translate(-50%, -160%);
}
.typing {
  position: absolute;
  top: 10%;
  min-height: 200px;
  width: 1000px;
  text-align: center;
  word-break: break-all;
  font-size: 27px;
  font-weight: 400;
  color: #faf4e9;
  line-height: 50px;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.77);
  transform: translate(-50%, 20%);
}
.btnbar {
  position: relative;
  transform: translate(-50%, -50%);
  cursor: default;
}
.cardField {
}
</style>
