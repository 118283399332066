import { defineStore } from "pinia";

const useStore = defineStore("main", {
  state: () => ({
    entryVideoStatus: "ready",
    needPlayEntry:!(process.env.VUE_APP_PLAY_ENTRY_VIDEO=="false"),
    auctionState: "welcome",
  }),
  getters: {},
  actions: {
    uploadEntryStatus(status) {
      this.entryVideoStatus = status;
    },
  },
});

export default useStore;
