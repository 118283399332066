import {
  createWeb3Modal,
  defaultWagmiConfig,
} from '@web3modal/wagmi/vue'
import { mainnet, arbitrum } from '@wagmi/core/chains'
import {
  sepolia,
} from '@wagmi/core'
const metadata = {
  name: 'Castile',
  description: 'Castile Web',
  url: 'http://www.castile.world',
  icons: ['http://www.castile.world/favicon.png'],
}
const chains = [mainnet]
if (process.env.VUE_APP_MODE !== 'production') {
  chains.push(sepolia)
}
const projectId = process.env.VUE_APP_WC_PID
export const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

export const createModal = () => {
  createWeb3Modal({
    wagmiConfig,
    projectId,
    chains,
    allWallets: 'HIDE',
    allowUnsupportedChain: false,
    enableOnramp: false,
    featuredWalletIds: [
      'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
      '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
    ],
    includeWalletIds: [
      'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
      '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
    ],
  })
}