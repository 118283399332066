"use strict";

export var Deck = (function () {
  "use strict";

  var style = document.createElement("p").style;
  var memoized = {};

  function prefix(param) {
    if (typeof memoized[param] !== "undefined") {
      return memoized[param];
    }

    if (typeof style[param] !== "undefined") {
      memoized[param] = param;
      return param;
    }

    var camelCase = param[0].toUpperCase() + param.slice(1);
    var prefixes = ["webkit", "moz", "Moz", "ms", "o"];
    var test;

    for (var i = 0, len = prefixes.length; i < len; i++) {
      test = prefixes[i] + camelCase;
      if (typeof style[test] !== "undefined") {
        memoized[param] = test;
        return test;
      }
    }
  }

  var transform = prefix("transform");
  var $p = document.createElement("p");

  document.body.appendChild($p);

  $p.style[transform] = "translate3d(1px,1px,1px)";

  var has3d = $p.style[transform];

  has3d = has3d != null && has3d.length && has3d !== "none";

  document.body.removeChild($p);

  function translate(a, b, c) {
    c = c || 0;
    if (has3d) {
      return "translate3d(" + a + ", " + b + ", " + c + ")";
    } else {
      return "translate(" + a + ", " + b + ")";
    }
  }

  var fan = {
    deck: function deck(_deck5) {
      _deck5.fan = _deck5.queued(fan);

      function fan(next) {
        var cards = _deck5.cards;
        var len = cards.length;
		
		var sWidth = document.body.clientWidth;
		var sHeight = document.body.clientHeight;
		//console.log("  ----   " + sWidth);
		//console.log("  ----   " + sHeight);
		var rotDeg = 20;
		if(sHeight > sWidth)
		{
			rotDeg = 14;
		}

        // 从deck节点移除所有节点
        cards.forEach(function (oneCard) {
          oneCard.unmount();
        });

        // 洗牌
        let cardNum = cards.length;
        for (let i = cardNum - 1; i > 0; i--) {
          let randomIndex = Math.floor(Math.random() * i); //生成一个随机数作为数组的一个index
          [cards[i], cards[randomIndex]] = [cards[randomIndex], cards[i]]; //交换当前index的元素和生成的随机index的元素的值
        }

        // 从deck节点移除所有节点
        cards.forEach(function (oneCard) {
          oneCard.mount(_deck5.$el);
        });
		
        cards.forEach(function (card, i) {
          //console.log(cards[i].isSelect);
		  if(cards[i].isSelect > 0)
		  {
			  
		  }
          cards[i].isSelect = -1;
          if (cards[i].isReverse == true) {
            cards[i].$faceFront.style[transform] = "rotateX(" + 180 + "deg)";
          }
          cards[i].isReverse = false;
          card.fan(i, len, rotDeg, function (i) {
            if (i === cards.length - 1) {
              next();
            }
          });
        });
      }
    },
    card: function card(_card5) {
      var transform = prefix("transform");
      var transformOrigin = prefix("transformOrigin");
      var transition = prefix("transition");
      var transitionDelay = prefix("transitionDelay");

      var $el = _card5.$el;

      _card5.fan = function (i, len, rtDeg, cb) {
        var z = i / 4;
        //var delay = i * 10;
        var delay = 0;
        //var rot = (i / (len - 1)) * 60 - 30;
        var rot = (i / (len - 1)) * rtDeg - rtDeg * 0.5;

        var beginRot = -rtDeg * 0.5;

        _card5.oriAngle = rot;
        $el.style[transformOrigin] = "50% 1200%";

        setTimeout(function () {
          $el.style[transition] =
            "all .3s cubic-bezier(0.645, 0.045, 0.355, 1.000)";
          $el.style[transitionDelay] = delay / 1000 + "s";
          //$el.style[transform] = translate(-z + 'px', -z + 'px');
          $el.style[transform] =
            translate(0, 0) + "rotate(" + beginRot + "deg)";
          $el.style.zIndex = i;

          setTimeout(function () {
            $el.style[transitionDelay] = "";
            $el.style[transform] = translate(0, 0) + "rotate(" + rot + "deg)";
          }, 300 + delay);
        }, 0);

        setTimeout(function () {
          cb(i);
        }, 1000 + delay);
      };
    },
  };

  function createElement(type) {
    return document.createElement(type);
  }

  var maxZ = 78;

  function Card(i) {
    var transition = prefix("transition");
    var transform = prefix("transform");

    var value = i + 1;
    var name = value;
    var suit = (i / 13) | 0;
    //var suitName = SuitName(suit);
    var suitName = SuitName(value);
    var z = (78 - i) / 4;

    var $el = createElement("div");
    var $topleft = createElement("div");
    //var $bottomright = createElement('div');
    var $faceBack = createElement("div");
    var $faceFront = createElement("div");

    var isMovable = false;
    var isSelect = -1;
    var isReverse = false;
    var isShowSFx = false;

    var oriAngle = 0;

    var self = {
		i: i,
		value: value,
		suit: suit,
		pos: i,
		$el: $el,
		$faceBack: $faceBack,
		$faceFront: $faceFront,
		mount: mount,
		unmount: unmount,
		isSelect: isSelect,
		oriAngle: oriAngle,
		suitName: suitName,
		isReverse: isReverse,
		isShowSFx: isShowSFx,
    };

    var modules = Deck.modules;
    var module;

    //$el.classList.add('card', suitName, suitName + value);
    $el.classList.add("card", suitName);
    $topleft.classList.add("topleft");
    //$bottomright.classList.add('bottomright');
    $faceBack.classList.add("back");
    //$faceFront.classList.add('face');
    $faceFront.classList.add("face");
	//$faceBack.classList.add("back");

    $topleft.textContent = name;
    //$bottomright.textContent = suit < 4 ? name : 'J\nO\nK\nE\nR';

    $el.style.zIndex = 78 - i;
    $el.style[transform] = "translate(-" + z + "px, -" + z + "px)";

    $el.appendChild($faceBack);
    $el.appendChild($faceFront);
    //$el.appendChild($topleft);
    //$el.appendChild($bottomright);

    for (module in modules) {
      addModule(modules[module]);
    }

    self.enableMoving = function () {
      if (isMovable) {
        // Already is movable, do nothing
        return;
      }
      //$el.style.cursor = 'move';
      addListener($el, "click", onMousedown);
      addListener($el, "touchstart", onMousedown);
      addListener($el, "mouseover", onMouseOver);
      addListener($el, "mouseout", onMouseOut);
    };

    self.disableMoving = function () {
      if (!isMovable) {
        // Already disabled moving, do nothing
        return;
      }
      $el.style.cursor = "";
      removeListener($el, "click", onMousedown);
      removeListener($el, "touchstart", onMousedown);
    };

    return self;

    function addModule(module) {
      module.card && module.card(self);
    }

    function onMousedown(e)
	{
		if (self.isSelect > 0 || Deck.selectCardNum > 2)
		{
			if(self.isSelect > 0)
			{
				Deck.ClickSelectedCard = true;
				//Deck.CSelectedCardName = Deck.CardNameList[self.value - 1];
			}
			
			return;
		}
		
		Deck.ClickSelectedCard = false;
		// 复位突出卡片
		$faceBack.style[transform] = "translateY(0px)";
		$topleft.style[transform] = "translateY(0px)";

		var middlePoint = self.$root.getBoundingClientRect();
		var pos = {};

		// 阻止默认事件
		e.preventDefault();

		var isR = Math.floor(Math.random() * 100 + 1);

		if (e.type === "mousedown" || e.type === "click") {
			//pos.x = e.clientX;
			//pos.y = e.clientY;
			//addListener(window, 'mousemove', onMousemove);
			//addListener(window, 'mouseup', onMouseup);

			pos.x = Deck.cardOffArr[Deck.selectCardNum][0];
			//pos.y =	document.body.clientHeight * 0.5 * 0.86 + Deck.cardOffArr[Deck.selectCardNum][1];
			pos.y =	Deck.cardOffArr[Deck.selectCardNum][1];
		} else {
			pos.x = e.touches[0].clientX;
			pos.y = e.touches[0].clientY;
			//addListener(window, 'touchmove', onMousemove);
			//addListener(window, 'touchend', onMouseup);

			pos.x = Deck.cardOffArr[Deck.selectCardNum][0];
			//pos.y = document.body.clientHeight * 0.5 * 0.86 + Deck.cardOffArr[Deck.selectCardNum][1];
			pos.y = Deck.cardOffArr[Deck.selectCardNum][1];
      }

      $el.style[transition] = "all .4s cubic-bezier(0.215, 0.610, 0.355, 1.000)";
      //$el.style[transform] = translate(pos.x + "px", pos.y - middlePoint.top + "px") + "rotateY(" + 180 + "deg)";
	  $el.style[transform] = translate(pos.x + "px", pos.y + "px") + "rotateY(" + 180 + "deg)";
      $el.style.zIndex = maxZ++;
      $el.classList.add("selected");
      if (isR % 2 === 0) {
        self.isReverse = true;
        $faceFront.style[transform] = "rotateX(" + 180 + "deg)";
      }

	  Deck.ChooseCards[Deck.selectCardNum] = self;
      self.isSelect = ++Deck.selectCardNum;
      //Deck.selectCardNum++;

	  if(Deck.selectCardNum >= 3)
	  {
		  setTimeout(() => {
			Deck.ChooseCardsFin(Deck.ChooseCards[0], Deck.ChooseCards[1], Deck.ChooseCards[2]);
		  }, 1000);
	  }
		
	  /*
      function onMousemove(e) {
        var pos = {};

        if (e.type === "mousemove") {
          pos.x = e.clientX;
          pos.y = e.clientY;
        } else {
          pos.x = e.touches[0].clientX;
          pos.y = e.touches[0].clientY;
        }

        $el.style[transition] = "";
        $el.style[transform] = translate(
          pos.x - middlePoint.left + "px",
          pos.y - middlePoint.top + "px"
        );
      }

      function onMouseup(e) {
        if (e.type === "mouseup") {
          removeListener(window, "mousemove", onMousemove);
          removeListener(window, "mouseup", onMouseup);
        } else {
          removeListener(window, "touchmove", onMousemove);
          removeListener(window, "touchend", onMouseup);
        }
      }
	  */
    }

    function onMouseOver(e) {
      //console.log(name);

      if (self.isSelect > 0) {
		  
		  return;
		  
        //var middlePoint = self.$root.getBoundingClientRect();
        const rect = $el.getBoundingClientRect();
        // 计算元素在屏幕中的中央位置
        //const centerX = rect.left + rect.width * 0.5 - middlePoint.x;
        //const centerY = rect.top + rect.height * 0.5 - middlePoint.y;

        //Deck.FixCardBackFx(true, centerX, centerY);
        $el.insertBefore(Deck.CardBackFx, $faceBack);
        $el.appendChild(Deck.CardFrontFx);
        Deck.CardBackFx.style[transform] = translate(rect.width * 0.5 + "px", rect.height * 0.5 + "px");
		
		var cardScaleX = rect.width / 104;
		var cardScaleY = rect.height / 180
		var fxScaleX = 0.9 * cardScaleX;
		var fxScaleY = 1.3 * cardScaleY;
        //Deck.CardFrontFx.style[transform] = translate(rect.width * 0.5 + "px", rect.height * 0.5 + "px");
		Deck.CardFrontFx.style[transform] = translate(rect.width * 0.5 + 'px', rect.height * 0.5 + 'px') + 'scale3d(' + fxScaleX + ',' + fxScaleY + ', 1)';
		
        self.isShowSFx = true;
		ChangeUV();
        return;
      }

      // 阻止默认事件
      e.preventDefault();

      $faceBack.style[transform] = "translateY(-10px)";
      $topleft.style[transform] = "translateY(-10px)";
    }

    function onMouseOut(e) {
      if (self.isSelect > 0) {
		  
		  return;
		  
        if (self.isShowSFx == true) {
          $el.removeChild(Deck.CardFrontFx);
          $el.removeChild(Deck.CardBackFx);
          self.isShowSFx = false;
        }
        //Deck.FixCardBackFx(false);
      }

      // 阻止默认事件
      e.preventDefault();

      $faceBack.style[transform] = "translateY(0px)";
      $topleft.style[transform] = "translateY(0px)";
    }
	
	function ChangeUV()
	{
		const cardRect = $el.getBoundingClientRect();
		var cardScaleX = cardRect.width / 104;
		var cardScaleY = cardRect.height / 180;
		
		const uvRect = Deck.CardFrontFxUV.getBoundingClientRect();
		var uvScaleX = uvRect.width / 4000;
		var uvScaleY = uvRect.height / 2400;
		
		//console.log("uvRect.width   " + uvRect.width);
		//console.log("uvRect.height   " + uvRect.height);
		//console.log("uvScaleX   " + uvScaleX);
		//console.log("uvScaleY   " + uvScaleY);
		
		var hInd = Math.floor(Deck.CurUVIndex % Deck.HUVNum);
		var vInd = Math.floor(Deck.CurUVIndex / Deck.HUVNum);
		
		var tranX = -Deck.PicWidth * hInd - (1 - cardScaleX) * Deck.PicWidth * 0.5;
		var tranY = -Deck.PicHeight * vInd - (1 - cardScaleY) * Deck.PicHeight * 0.5;
		
		//Deck.CardBackFxUV.style[transform] = 'translate3d(' + tranX + 'px,' + tranY + 'px, 0)';
		Deck.CardFrontFxUV.style[transform] = 'translate3d(' + tranX + 'px,' + tranY + 'px, 10px)';
		/*
		Deck.tempIdx++;
		if(Deck.tempIdx >= 60)
		{
			Deck.CurUVIndex++;
			Deck.tempIdx = 0;
		}
		*/
		Deck.CurUVIndex++;
		if(Deck.CurUVIndex >= Deck.TotalUVNum)
		{
			Deck.CurUVIndex = 0;
		}
			
		if(self.isShowSFx == true)
		{
			window.requestAnimationFrame(ChangeUV);
		}
	}

    function mount(target) {
      target.appendChild($el);
      self.$root = target;
    }

    function unmount() {
      self.$root && self.$root.removeChild($el);
      self.$root = null;
    }

    function AttachSelectFx(fFx, bFx) {}

    function RemoveSelectFx(fFx, bFx) {}
  }

  function SuitName(value) {
    //return value === 0 ? 'spades' : value === 1 ? 'hearts' : value === 2 ? 'clubs' : value === 3 ? 'diamonds' : 'joker';
    return "tarot" + value;
  }

  function addListener(target, name, listener) {
    target.addEventListener(name, listener);
  }

  function removeListener(target, name, listener) {
    target.removeEventListener(name, listener);
  }

  function easing(name) {
    if (name === "cubicInOut") {
      return "cubic-bezier(0.645, 0.045, 0.355, 1.000)";
    }
  }

  function queue(target) {
    var array = Array.prototype;

    var queueing = [];

    target.queue = queue;
    target.queued = queued;

    return target;

    function queued(action) {
      return function () {
        var self = this;
        var args = arguments;

        queue(function (next) {
          action.apply(self, array.concat.apply(next, args));
        });
      };
    }

    function queue(action) {
      if (!action) {
        return;
      }

      queueing.push(action);

      if (queueing.length === 1) {
        next();
      }
    }
    function next() {
      queueing[0](function (err) {
        if (err) {
          throw err;
        }

        queueing = queueing.slice(1);

        if (queueing.length) {
          next();
        }
      });
    }
  }

  function observable(target) {
    target || (target = {});
    var listeners = {};

    target.on = on;
    target.one = one;
    target.off = off;
    target.trigger = trigger;

    return target;

    function on(name, cb, ctx) {
      listeners[name] || (listeners[name] = []);
      listeners[name].push({ cb: cb, ctx: ctx });
    }

    function one(name, cb, ctx) {
      listeners[name] || (listeners[name] = []);
      listeners[name].push({
        cb: cb,
        ctx: ctx,
        once: true,
      });
    }

    function trigger(name) {
      var self = this;
      var args = Array.prototype.slice(arguments, 1);

      var currentListeners = listeners[name] || [];

      currentListeners.filter(function (listener) {
        listener.cb.apply(self, args);

        return !listener.once;
      });
    }

    function off(name, cb) {
      if (!name) {
        listeners = {};
        return;
      }

      if (!cb) {
        listeners[name] = [];
        return;
      }

      listeners[name] = listeners[name].filter(function (listener) {
        return listener.cb !== cb;
      });
    }
  }

  function Deck() {
    var cards = new Array(78);

	var $elffx_mask = createElement('div');
    var $elffx = createElement("div");
    var $elbfx = createElement("div");

    var $el = createElement("div");
    var self = observable({
      mount: mount,
      unmount: unmount,
      cards: cards,
      $el: $el,
    });
    var $root;

    var modules = Deck.modules;
    var module;

    queue(self);

    for (module in modules) {
      addModule(modules[module]);
    }

    $el.classList.add("deck");
	$elffx_mask.classList.add('cardFfxMask');
    $elffx.classList.add("cardFfx");
    $elbfx.classList.add("deckfx");
	
	$elffx_mask.appendChild($elffx);

	Deck.CardFrontFx = $elffx_mask;
	Deck.CardFrontFxUV = $elffx;
    Deck.CardBackFx = $elbfx;
	Deck.CurUVIndex = 1;
	
	Deck.textEl1 = createElement("div");
	Deck.textEl1.innerHTML = "PAST";
	Deck.textEl1.classList.add('timeState');
	Deck.textEl1.style[transform] = 'translate3d(' + Deck.cardTextOffArr[0][0] + 'px,' + Deck.cardTextOffArr[0][1] + 'px, 2px)';
	
	Deck.textEl2 = createElement("div");
	Deck.textEl2.innerHTML = "PRESENT";
	Deck.textEl2.classList.add('timeState');
	Deck.textEl2.style[transform] = 'translate3d(' + Deck.cardTextOffArr[1][0] + 'px,' + Deck.cardTextOffArr[1][1] + 'px, 2px)';
	
	Deck.textEl3 = createElement("div");
	Deck.textEl3.innerHTML = "FUTURE";
	Deck.textEl3.classList.add('timeState');
	Deck.textEl3.style[transform] = 'translate3d(' + Deck.cardTextOffArr[2][0] + 'px,' + Deck.cardTextOffArr[2][1] + 'px, 2px)';
	
	$el.appendChild(Deck.textEl1);
	$el.appendChild(Deck.textEl2);
	$el.appendChild(Deck.textEl3);

    var card;

    for (var i = 0, len = cards.length; i < len; i++) {
      card = cards[i] = Card(i);
      card.mount($el);
    }

    return self;

    function mount(root) {
      $root = root;
      $root.appendChild($el);
      //Deck.FixCardBackFx = FixCardBackFx;
	  
	  Deck.deckRoot = $el;
    }

    function unmount() {
      $root.removeChild($el);
    }

    function addModule(module) {
      module.deck && module.deck(self);
    }

    /*
		function FixCardBackFx(show, posX, posY)
		{
			if(show == true)
			{
				Deck.ShowBackFx = true;
				$el.appendChild($elfx);
				
				$elfx.style[transform] = translate(posX + 'px', posY + 'px')
			}
			else
			{
				if(Deck.ShowBackFx == true)
				{
					$el.removeChild($elfx);
				}
				Deck.ShowBackFx = false;
			}
		}
		*/
		
	function ResetChooseCardPos()
	{
		var rect = Deck.deckRoot.getBoundingClientRect();
		console.log(rect.top);
	}
  }

	Deck.curDeck = null;
	Deck.modules = { fan: fan };
	Deck.Card = Card;
	Deck.easing = easing;
	Deck.prefix = prefix;
	Deck.translate = translate;
	Deck.deckRoot = null;

	Deck.textEl1 = null;
	Deck.textEl2 = null;
	Deck.textEl3 = null;
	
	//Deck.ShowBackFx = false;
	//Deck.FixCardBackFx = null;
	Deck.CardFrontFx = null;
	Deck.CardFrontFxUV = null;
	Deck.CardBackFx = null;
	
	Deck.PicWidth = 400;
	Deck.PicHeight = 400;
	Deck.PicScaleWidth = 350;
	Deck.PicScaleHeight = 450;
	Deck.CurUVIndex = 0;
	Deck.HUVNum = 10;
	Deck.VUVNum = 6;
	Deck.TotalUVNum = 60;
	
	Deck.tempIdx = 0;

	Deck.selectCardNum = 0;
	Deck.cardTextOffArr = [
		[-156, -160],
		[0, -180],
		[156, -160],
	];
	Deck.cardOffArr = [
		[-156, -250],
		[0, -270],
		[156, -250],
	];
	Deck.hintTextY = -460;
	Deck.ClickSelectedCard = false;
	//Deck.CSelectedCardName = "";
	Deck.ChooseCards = [null, null, null];
	Deck.ChooseCardsFin = null;
	Deck.CallCardAgain = null;
	
	Deck.CardNameList = [
		'ACE OF CUPS',
		'ACE OF PENTACLES',
		'ACE OF SWORDS',
		'ACE OF WANDS',
		'EIGHT OF CUPS',
		'EIGHT OF PENTACLES',
		'EIGHT OF SWORDS',
		'EIGHT OF WANDS',
		'FIVE OF CUPS',
		'FIVE OF PENTACLES',
		'FIVE OF SWORDS',
		'FIVE OF WANDS',
		'FOUR OF CUPS',
		'FOUR OF PENTACLES',
		'FOUR OF SWORDS',
		'FOUR OF WANDS',
		'KING OF CUPS',
		'KING OF PENTACLES',
		'KING OF SWORDS',
		'KING OF WANDS',
		'KNIGHT OF CUPS',
		'KNIGHT OF PENTACLES',
		'KNIGHT OF SWORDS',
		'KNIGHT OF WANDS',
		'NINE OF CUPS',
		'NINE OF PENTACLES',
		'NINE OF SWORDS',
		'NINE OF WANDS',
		'PAGE OF CUPS',
		'PAGE OF PENTACLES',
		'PAGE OF SWORDS',
		'PAGE OF WANDS',
		'QUEEN OF CUPS',
		'QUEEN OF PENTACLES',
		'QUEEN OF SWORDS',
		'QUEEN OF WANDS',
		'SEVEN OF CUPS',
		'SEVEN OF PENTACLES',
		'SEVEN OF SWORDS',
		'SEVEN OF WANDS',
		'SIX OF CUPS',
		'SIX OF PENTACLES',
		'SIX OF SWORDS',
		'SIX OF WANDS',
		'Temperance',
		'TEN OF CUPS',
		'TEN OF_PENTACLES',
		'TEN OF_SWORDS',
		'TEN OF_WANDS',
		'The Chariot',
		'The Death',
		'The Devil',
		'The Emperor',
		'The Empress',
		'The Fool',
		'The Hanged_Man',
		'The Hermit',
		'The Hierophant',
		'The High Priestess',
		'The Judgement',
		'The Justice',
		'The Lover',
		'The Magician',
		'The Moon',
		'The Star',
		'The Strength',
		'The Sun',
		'The Tower',
		'The Wheel of Fortune',
		'The World',
		'THREE OF CUPS',
		'THREE OF PENTACLES',
		'THREE OF SWORDS',
		'THREE OF WANDS',
		'TWO OF CUPS',
		'TWO OF PENTACLES',
		'TWO OF SWORDS',
		'TWO OF WANDS',
	]

  return Deck;
})();
