<template>
  <DialogGuest :show="show" @close="$emit('close')">
    <div class="congrats-wrapper">
      <div class="title">LOG IN</div>
      <div class="title-footer"></div>

      <div class="congrats-dialog-footer">
        <form class="ui form mini" @submit.prevent="debounce(login)">
          <div class="field">
            <label>Email</label>
            <input type="email" v-model.trim="email" required />
          </div>
          <div class="field">
            <label>Password</label>
            <div class="ui icon input">
              <input :type="passwordStatus" v-model.trim="password" required />
              <i
                class="eye large icon link"
                :class="{ slash: passwordStatus == 'password' }"
                @click="togglePassword"
              ></i>
            </div>
          </div>
          <div class="field forget">
            <label>
              <a @click="$emit('toForget')" class="a-tag"
                >Forget your password ?</a
              ></label
            >
          </div>
          <div class="field create-button">
            <button class="ui button mini" type="submit">LOG IN</button>
            <p class="font-normal">
              Don`t have an account yet?
              <a @click="$emit('toRegister')" class="a-tag">Sign up here</a>
            </p>
          </div>
        </form>
      </div>
    </div>
  </DialogGuest>
</template>

<script setup>
import { ref, onMounted, toRef, toRefs } from 'vue';
import DialogGuest from '@/components/DialogGuest.vue';
import http from '@/utils/http.js';
import { useToast } from 'vue-toastification';
import useGlobal from '@/store/modules/global';
import { debounce } from '@/utils/debounce-throttle';

const toast = useToast();
const global = useGlobal();
const emit = defineEmits(['close', 'toRegister', 'bindWallet', 'forget']);

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
});
const { show } = toRefs(props);

const email = ref(
  process.env.VUE_APP_MODE === 'development' ? 'kszoepe@gmail.com' : ''
);
const password = ref(
  process.env.VUE_APP_MODE === 'development' ? '123456' : ''
);
const passwordStatus = ref('password');

const togglePassword = () => {
  passwordStatus.value =
    passwordStatus.value === 'password' ? 'text' : 'password';
};

const login = () => {
  http
    .post('/api/guest/login', { email: email.value, password: password.value })
    .then(function (res) {
      if (!res) return false;

      if (res.data.status.code != 10200) {
        toast.error(res.data.status.msg);
        return false;
      }

      localStorage.setItem('authorization', res.data.data.auth);
      global.setUserInfo(res.data.data);
      emit('close');
      emit('bindWallet');
    });
};
</script>

<style lang="less" scoped>
.m-view-congrats {
  width: 100vw;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.congrats-wrapper {
  width: 500px;
  padding-top: 73px;
  padding-bottom: 70px;
  padding-left: 60px;
  padding-right: 50px;
  box-sizing: content-box;
  background-color: #000;
  .title {
    font-size: 57px;
    font-weight: 400;
    color: #d8d5d0;
    background: linear-gradient(0deg, #f9f6c3 0%, #e7a952 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    line-height: 40px;
  }
  .title-footer {
    height: 5px;
    margin-bottom: 38px;
    background: url('@/assets/img/title-line.png');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
.congrats-dialog-footer {
  display: flex;
  justify-content: space-around;
  .van-button {
    width: 241px;
    font-size: 20px;
    padding: 0;
  }
}
.ui.form.mini {
  width: 100%;
}
.ui.form .checkbox label {
  font-size: 14px;
  font-family: auto;
}
.ui.form label {
  color: #ede7dc;
  font-size: 18px;
  font-weight: 400;
}
.ui.form input {
  color: rgba(255, 255, 255, 0.85);
  background: #343434;
  border-radius: 0;
}
.ui.form input:focus {
  color: rgba(255, 255, 255, 0.85);
  border-color: #85b7d9;
  background: #343434;
  border-radius: 0;
  box-shadow: 0;
}
.ui.button {
  font-family: Celtic-Garamond-the-2nd;
  background: #c5b7a6;
  border-radius: 0;
  font-size: 18px;
  letter-spacing: 2px;
  color: #000;
  line-height: 14px;
  height: 40px;
  width: 300px;
  margin: 0 auto;
}
.create-button {
  text-align: center;
  margin-top: 40px !important;
}
.email-plane {
  width: 100px !important;
  border-radius: 0 !important;
}
.ui.icon.button > .icon {
  margin: 0 auto !important;
}
.a-tag {
  font-family: auto;
  cursor: pointer;
  color: #edb758;
}
.font-normal {
  font-size: 14px;
  margin-top: 30px;
  font-family: auto;
}
.eye.icon {
  color: #5f5f5d;
}
.forget {
  text-align: right;
}
.forget a {
  font-size: 14px;
}
</style>
