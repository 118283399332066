<style lang="less" scoped>
.tail {
  padding: 1rem 0;
  width: 100%;
  background-color: #000000;
  text-align: center;
}
.tail-absolute {
  position: absolute;
  bottom: 0; /* 将div放置在底部 */
  padding: 1rem 0;
  width: 100%;
  background-color: #000000;
  text-align: center;
}
p,
a {
  font-family: ProximaNova-Regular;
  margin-top: 0.5rem;
  color: #99958e;
  font-size: 1rem;
}
@media only screen and (max-width: 600px) {
  .tail {
    padding: 14px 0;
  }
  p {
    margin-top: 12px;
  }
}
</style>

<template>
  <div
    :class="[tailClass, 'xs:*:!text-xs xs:*:!mt-2 *:!text-sm *:!mt-1']"
    v-show="show"
  >
    <div
      class="*:object-cover *:*:h-5 *:mr-6 *:grayscale flex justify-center items-center mb-4"
    >
      <a href="https://x.com/Castileofficial" target="_blank">
        <img src="@/assets/img/icon_x.png" alt="X" />
      </a>
      <a href="https://discord.gg/castileofficial" target="_blank">
        <img src="@/assets/img/icon_Discord.png" alt="Discord" />
      </a>
      <!-- <a
        href="https://nytrolab.gitbook.io/nytro-lab.whitepaperen"
        target="_blank"
      >
        <img src="@/assets/img/icon_whitepaper.png" alt="Whitepaper" />
      </a> -->
      <a href="https://www.youtube.com/@Castileofficial" target="_blank">
        <img src="@/assets/img/icon_youtube.png" alt="YouTube" />
      </a>
      <a href="https://medium.com/@Castileofficial" target="_blank">
        <img src="@/assets/img/icon_Medium.png" alt="Medium" class="!mr-0" />
      </a>
    </div>

    <p class="!mt-0">You Long Infinite Interactive Entertainment Co. LTD</p>
    <p>RM1201,12/F PROPERITY CTR 982 CANTON RD MONGKOK KLN HONGKONG</p>
    <p>Email: castileofficial@castile.world</p>
  </div>
</template>

<script setup>
import { ref, onMounted, toRefs } from 'vue';
const tailClass = ref('tail');

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  absolute: {
    type: Boolean,
    default: false,
  },
});
const { show, absolute } = toRefs(props);

onMounted(() => {
  if (absolute.value == true) {
    tailClass.value = 'tail-absolute';
  }
});
</script>
