import { defineStore } from "pinia";

const useStore = defineStore("auction", {
  persist: true,
  state: () => ({
    auctionState: "welcome",
    showAuction: false,
    userInfo: [],
    address: ''
  }),
  getters: {},
  actions: {
      initLogic(){
          //this.showAuction = true
          //this.auctionState = 'auction'
          //return false

          if(!this.userInfo.hasOwnProperty('gain')){
              return false
          }

          if(this.userInfo.gain){
              this.gainBid()
              return false
          }

          this.joinCase()
      },
      gainBid(){
          this.showAuction = true
          if(this.userInfo.step3.paid){
              this.completed()
              return false
          }

          if(this.userInfo.step3.latest <= 0){
              this.isExpired()
              return false
          }

          this.auctionState = 'pay';
      },
      joinCase(){
          if(!this.userInfo.cases.hasOwnProperty('open'))
              return false

          this.showAuction = true

          if(Number(this.userInfo.step1.join) > 0){
              this.gotoBid()
              return false
          }

          if(this.userInfo.cases.isEnd){
              this.casesEnded()
              return false
          }

          this.auctionState = 'welcome'
      },
      gotoBid(){
          if(this.userInfo.step2.latest <= 0){
              this.isExpired()
              return false
          }

          this.auctionState = 'auction'
      },
      isExpired(){
          this.auctionState = 'sorry'
      },
      completed(){
          this.auctionState = 'completed'
      },
      casesEnded(){
          this.auctionState = 'end'
      },


      updateAuctionStatus(status) {
          this.auctionState = status
      },

      resetAuctionState() {
          this.auctionState = "welcome"
      },
      resetUserInfo() {
          this.showAuction = false
          this.userInfo = {}
      },
      printUserInfo() {
      },
  },
});

export default useStore;
