import { defineStore } from "pinia"
import http from "@/utils/http.js"


const useGlobal = defineStore("global", {
	persist: true,
	state: () => ({
		uid: 0,
		email: '',
		username: '',
		notNow: false,
		wallet: '',
		aptosAddress: '',
		aptosWallet: '',
	}),
	getters: {},
	actions: {
		setUserInfo(data) {
			this.username = data.username
			this.email = data.email
			this.uid = data.uid
		},
		pullUserInfo() {
			return new Promise((resolve, reject) => {
				let that = this
				http.post('/api/user/pullUserInfo').then(function (res) {
					that.username = res.data.data.username
					that.email = res.data.data.email
					that.uid = res.data.data.uid
					resolve(res)
				}).catch((err) => {
					reject(err)
				})
			})
		},
		logout() {
			this.uid = 0
			this.email = ''
			this.username = ''
			localStorage.removeItem('global')
			localStorage.removeItem('authorization')
		}
	},
})

export default useGlobal
