<template>
  <AptosWalletAdapter>
    <mHeader></mHeader>

    <router-view ref="routerRef"></router-view>
  </AptosWalletAdapter>

  <div class="loader-wrapper vch" v-if="showLoading">
    <div class="loading-container flex-column center">
      <img src="@/assets/img/home_loading.png" class="loading-image" />
      <p class="progress-text">{{ progress }}%</p>
      <van-button
        v-if="progress >= 100"
        @click="handleEnter"
        style="margin-top: 20px"
        >ENTER</van-button
      >
    </div>
  </div>

  <Transition>
    <div v-if="showEntryVideo" class="entry-video-wrapper">
      <video
        :src="entryVideoResource"
        autoplay
        preload="auto"
        class="entry-video center"
        ref="entryVideoRef"
        x-scroll="false"
        x5-video-player-fullscreen="false"
        x5-playsinline="true"
        playsinline="true"
        webkit-playsinline="true"
        :controls="false"
        @ended="handleVideoEnd"
      ></video>
    </div>
  </Transition>

  <div class="audio-btn" @click="handleAudio">
    <img
      class="audio-icon"
      v-if="audioIsPlay"
      src="@/assets/img/audio-pause.png"
    />
    <img class="audio-icon" v-else src="@/assets/img/audio-play.png" />
  </div>

  <!-- <GoogleRecaptcha></GoogleRecaptcha> -->
</template>

<script setup>
import mHeader from '@/layout/header.vue';
import GoogleRecaptcha from '@/layout/GoogleRecaptcha.vue';
import { ref, onMounted, nextTick } from 'vue';
import { preloadResource } from '@/utils/http.js';
import { useRoute } from 'vue-router';

import useStore from './store/modules/main';
import AptosWalletAdapter from './components/AptosWalletAdapter.vue';

const state = useStore();
const route = useRoute();
const routerRef = ref();
const progress = ref(0);
const showLoading = ref(true);
const showEntryVideo = ref(true);
const entryVideoRef = ref();
const entryVideoResource = ref();
const isMobile = ref(false);
const audio = new Audio(require('@/assets/music/Audio_Library_music.mp3'));
const audioIsPlay = ref(false);

//初始化入口
onMounted(() => {
  isMobile.value = document.body.clientWidth <= 768;
  const path = window.location.hash.replace('#', '');

  if (route.query?.promote_code) {
    localStorage.setItem('promote_code', route.query.promote_code);
  }
  if (process.env.NODE_ENV === 'development') {
    nextTick(() => {
      finishEntry();
    });
    return;
  }
  //想判断是否需要播放入场视频
  if (
    !state.needPlayEntry ||
    path !== '' ||
    localStorage.getItem('isPlay') === 'true'
  ) {
    finishEntry();
    return;
  }

  if (entryVideoRef.value) {
    localStorage.setItem('isPlay', 'true');

    entryVideoRef.value.onplay = () => {
      state.uploadEntryStatus('playing');
      showLoading.value = false;
    };
    entryVideoRef.value.ontimeupdate = (res) => {
      if (entryVideoRef.value.currentTime >= 14) {
        state.uploadEntryStatus('ended');
        showEntryVideo.value = false;
        entryVideoRef.value.ontimeupdate = null;
      }
    };
  }

  preload();
});

const preload = () => {
  const url = isMobile.value
    ? require('@/assets/video/entry-m.mp4')
    : require('@/assets/video/entry.mp4');
  preloadResource(url, (process) => {
    progress.value = Number(parseFloat(process * 100).toFixed(2));
  })
    .then(({ data }) => {
      //这里判断pc端才使用源播放。移动端由于有兼容性问题，尝试使用下载缓存播放。
      if (!isMobile.value) {
        entryVideoResource.value = URL.createObjectURL(data);
        // entryVideoRef.value?.play();
      }
    })
    .catch(() => {});
};

//这里只有移动端才执行
//主要是移动端播放入场视频的方式，通过点击按钮事件触发播放。手机的视频地址注意要和preload的地址一致才能有效获取缓存。
const handleEnter = () => {
  showLoading.value = false;
  entryVideoResource.value = isMobile.value
    ? require('@/assets/video/entry-m.mp4')
    : require('@/assets/video/entry.mp4');

  entryVideoRef.value?.play();

  setTimeout(() => {
    document.querySelector('#bg-loop-video').play();
  }, 0);
};

const handleVideoEnd = () => {
  audio.loop = true;
  audio.play();
  audioIsPlay.value = true;
};

const handleAudio = () => {
  if (audioIsPlay.value) {
    audio.pause();
    audioIsPlay.value = false;
  } else {
    audio.play();
    audioIsPlay.value = true;
  }
};

//  结束播放入场视频逻辑=>把进度条设置100，删除入口视频的dom，更新入场视频的全局状态为ended
const finishEntry = () => {
  showLoading.value = false;
  progress.value = 100;
  showEntryVideo.value = false;
  state.uploadEntryStatus('ended');
};
</script>

<style lang="less">
#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #000;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.entry-video-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4000;
  position: fixed;

  .entry-video {
    width: 100%;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #000;
  height: 100vh;
  width: 100vw;

  .loading-container {
    width: 279px;

    .loading-image {
      width: 279px;
      height: 279px;
    }

    .progress-text {
      width: 100%;
      font-size: 24px;
      font-family: Celtic-Garamond-the-2nd;
      font-weight: 400;
      color: #e4d399;
      text-align: center;
      margin-top: 16px;
    }
  }
}

.audio-btn {
  position: fixed;
  width: 50px;
  height: 40px;
  bottom: 7px;
  right: 1px;
  z-index: 999;
  display: grid;
  place-items: center;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0.2);
}
.audio-icon {
  width: 20px;
}
.Vue-Toastification__toast-body {
  font-family: auto;
}
.Vue-Toastification__toast--warning {
  background-color: #db8c00;
}

@media (max-width: 640px) {
  .Vue-Toastification__container {
    top: 1rem !important;
  }
  .Vue-Toastification__toast {
    border-radius: 0.5rem;
    width: calc(100% - 1.5rem * 2) !important;
    padding: 1rem !important;
    margin: 0 auto;
    min-height: unset !important;
  }
}
</style>
