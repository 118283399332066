<template>
  <Dialog
    :show="show"
    @close="$emit('update:show', false)"
    :background-image="require('@/assets/img/dialog-bg-3.png')"
    :lazy-render="false"
  >
    <div class="congrats-wrapper xs:!p-8 xs:!w-auto">
      <p class="_title text-center text-2xl mb-4 xs:!text-2xl xs:!mb-4">
        Select Wallet
      </p>

      <div class="'ui aligned basic segment !p-0 xs:!gap-2',">
        <AptosConnect ref="AptosConnectRef">
          <template #default>
            <van-button
              :class="[
                'w-full',
                disabled == 'aptos' && 'opacity-60 !cursor-not-allowed',
              ]"
              @click="disabled != 'aptos' && $emit('connect', 'aptos')"
            >
              <div
                class="ui button !mr-0 !flex items-center justify-center gap-4 xs:!text-sm !px-4 w-full !bg-transparent"
              >
                <img
                  src="@/assets/img/purchase/APT.png"
                  alt=""
                  class="size-6"
                />
                {{
                  $attrs.aptosAddress
                    ? assignWallet($attrs.aptosAddress)
                    : 'Connect Aptos Wallet'
                }}
                <template v-if="$attrs.aptosAddress">
                  <i class="ui icon sign-out !inline-flex items-center"></i>
                </template>
              </div>
            </van-button>
          </template>
        </AptosConnect>
        <div
          class="ui horizontal divider font-normal my-4 !text-xs !text-white"
        >
          OR
        </div>
        <!-- 历史遗留问题，我无法封装 -->
        <van-button
          :class="[
            'w-full',
            disabled == 'eth' && 'opacity-60 !cursor-not-allowed',
          ]"
          @click="disabled != 'eth' && $emit('connect', 'ethereum')"
        >
          <div
            class="ui button !mr-0 !flex items-center justify-center gap-4 xs:!text-sm !px-4 w-full !bg-transparent"
          >
            <img src="@/assets/img/purchase/ETH.png" alt="" class="size-6" />
            {{
              $attrs.address
                ? assignWallet($attrs.address)
                : 'Connect Eth Wallet'
            }}
            <template v-if="$attrs.address">
              <i class="ui icon sign-out !inline-flex items-center"></i>
            </template>
          </div>
        </van-button>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import Dialog from '@/components/Dialog.vue';
import AptosConnect from '@/components/AptosConnect.vue';
import { useWalletUtils } from '@/hooks/useWalletUtils';
import { ref } from 'vue';

const { assignWallet } = useWalletUtils();
const emit = defineEmits(['connect']);
const AptosConnectRef = ref(null);
const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: String,
    default: 'off',
  },
});

defineExpose({
  AptosConnectRef,
});
</script>

<style lang="less" scoped>
.m-view-congrats {
  width: 100vw;
  height: 100%;
  background-image: url('@/assets/img/dialog-bg.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.congrats-wrapper {
  box-sizing: content-box;
  ._title {
    color: #d8d5d0;
    background: linear-gradient(0deg, #f9f6c3 0%, #e7a952 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .content {
    font-family: SourceHanSerif;
    color: #d8d5d0;
  }
}
</style>
