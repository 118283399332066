/**
 * 此示例用于web和petra通信，连接、断开、交易
 */
import { convertToAptosUnits } from './useWalletUtils'
import nacl from 'tweetnacl'
import { useRoute } from 'vue-router'
import { watch, onMounted } from 'vue'
import { useMediaQuery } from '@vueuse/core'

const PETRA_LINK_BASE = 'petra://api/v1'
const DAPP_LINK_BASE = window.origin
const APP_INFO = {
  domain: DAPP_LINK_BASE,
  name: location.hostname,
}

const cacheData = {
  secretKey: null,
  publicKey: null,
  account: null,
  sharedEncryptionSecretKey: null
}

// 将 Uint8Array 转换为 Base64 字符串
function uint8ArrayToBase64(uint8Array) {
  if (!uint8Array) return

  const binaryString = String.fromCharCode.apply(null, uint8Array)
  return btoa(binaryString)
}

// 将 Base64 字符串转换回 Uint8Array
function base64ToUint8Array(base64) {
  if (!base64) return
  const binaryString = atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}

function decodeFromBase64Url(base64UrlStr) {
  base64UrlStr = base64UrlStr.replace('-', '+').replace('_', '/')
  while (base64UrlStr.length % 4) {
    base64UrlStr += '='
  }
  var str = decodeURIComponent(escape(atob(base64UrlStr)))
  return str
}

const setLocal = () => {
  localStorage.setItem('cacheAptosData', JSON.stringify({
    ...cacheData,
    publicKey: uint8ArrayToBase64(cacheData.publicKey),
    secretKey: uint8ArrayToBase64(cacheData.secretKey),
    sharedEncryptionSecretKey: uint8ArrayToBase64(cacheData.sharedEncryptionSecretKey)
  }))
}
const getLocal = () => {
  const _d = JSON.parse(localStorage.getItem('cacheAptosData') || "{}")
  Object.assign(cacheData, {
    ..._d,
    publicKey: base64ToUint8Array(_d.publicKey),
    secretKey: base64ToUint8Array(_d.secretKey),
    sharedEncryptionSecretKey: base64ToUint8Array(_d.sharedEncryptionSecretKey)
  })
}


export const useMobileAptos = (cb) => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const route = useRoute()

  const generateAndSaveKeyPair = () => {
    const keyPair = nacl.box.keyPair()

    cacheData.secretKey = keyPair.secretKey
    cacheData.publicKey = keyPair.publicKey
    setLocal()
    return keyPair
  }

  const handleConnectionApproval = () => {
    if (!cacheData.secretKey) {
      throw new Error('Missing key pair')
    }

    console.log(cacheData)
    const sharedEncryptionSecretKey = nacl.box.before(
      Buffer.from(cacheData.account.petraPublicEncryptedKey.slice(2), 'hex'),
      cacheData.secretKey,
    )
    cacheData.sharedEncryptionSecretKey = sharedEncryptionSecretKey
    setLocal()
  }

  const mobileConnect = () => {
    const keyPair = generateAndSaveKeyPair()
    const data = {
      appInfo: APP_INFO,
      redirectLink: `${window.location.origin + window.location.pathname} `,
      dappEncryptionPublicKey: Buffer.from(keyPair.publicKey).toString('hex'),
    }

    cacheData.account = null
    cacheData.sharedEncryptionSecretKey = null
    setLocal()

    window.location.href = `${PETRA_LINK_BASE}/connect?data=${btoa(JSON.stringify(data))}`
  }

  const mobileDisconnect = (publicKey) => {
    if (!publicKey) {
      throw new Error('Missing public key')
    }

    const data = {
      appInfo: APP_INFO,
      redirectLink: `${window.location.origin + window.location.pathname}`,
      dappEncryptionPublicKey: Buffer.from(publicKey).toString('hex'),
    }

    cacheData.publicKey = null
    cacheData.secretKey = null
    cacheData.account = null
    cacheData.sharedEncryptionSecretKey = null
    setLocal()

    window.open(
      `${PETRA_LINK_BASE}/disconnect?data=${btoa(JSON.stringify(data))}`,
    )
  }

  const mobileSignAndSubmitTransaction = (address, amount) => {
    if (!cacheData.sharedEncryptionSecretKey) {
      throw new Error('Missing shared public key')
    }

    if (!cacheData.publicKey) {
      throw new Error('Missing public key')
    }

    const transfer = {
      arguments: [
        address,
        convertToAptosUnits(amount).toString(),
      ],
      function: '0x1::coin::transfer',
      type: 'entry_function_payload',
      type_arguments: ['0x1::aptos_coin::AptosCoin'],
    }
    const payload = btoa(
      JSON.stringify(transfer),
    )

    const nonce = nacl.randomBytes(24)
    const encryptedPayload = nacl.box.after(
      Buffer.from(JSON.stringify(payload)),
      nonce,
      cacheData.sharedEncryptionSecretKey,
    )
    const data = btoa(
      JSON.stringify({
        appInfo: APP_INFO,
        payload: Buffer.from(encryptedPayload).toString('hex'),
        redirectLink: `${window.location.origin + window.location.pathname}`,
        dappEncryptionPublicKey: Buffer.from(cacheData.publicKey).toString('hex'),
        nonce: Buffer.from(nonce).toString('hex'),
      }),
    )

    window.location.href = `${PETRA_LINK_BASE}/signAndSubmit?data=${data}`
  }

  const getRouteParams = () => {
    const stop = watch(() => route.query, () => {
      if (route.query?.response === 'rejected') {
        toast.error('user rejected the request')
      }

      if (route.query?.response === "approved" && route.query?.data) {
        // 解析数据
        const data = JSON.parse(decodeFromBase64Url(route.query?.data))
        // 此时为支付成功
        if (data.success == true) {
          getLocal()
          stop()
          return
        }

        getLocal()
        // account = { address: 'xxx , publicKey: 'xxx', petraPublicEncryptedKey: 'xxx'}
        const account = data
        cacheData.account = account

        handleConnectionApproval(account.petraPublicEncryptedKey)
        cb(account)
        stop()
      }
    })
  }

  onMounted(() => {
    if (isMobile.value && Object.keys(route.query).length == 0) {
      console.log('get', cacheData.secretKey)
      getLocal()
      if (cacheData.account) {
        cb?.(cacheData.account)
      }
    }
  })

  return {
    isMobile,
    mobileConnect,
    mobileDisconnect,
    mobileSignAndSubmitTransaction,
    getRouteParams,
    decodeFromBase64Url
  }
}