import axios from "axios"
import i18n from "../i18n"
import Toast from "vue-toastification"
import { disconnect } from "@wagmi/core"
import "vue-toastification/dist/index.css"
import { useToast } from "vue-toastification"
import useGlobal from "@/store/modules/global"
import { createDebounce } from "./debounce-throttle"

const [debounce] = createDebounce()
//axios.defaults.withCredentials = true

const toast = useToast()
const http = axios.create({
    timeout: 300000,
    headers: {}
})

const errorMessage = function (code, text) {
    var initloading = document.getElementById('initloading')
    try {
        if (initloading.innerHTML.length > 0) {
            initloading.remove()
            document.getElementById('app').innerHTML = '<div class="init-faild"><code>' + code + '</code>' + text + '</div>'
            return false
        }
    } catch (e) { }
    toast.error(text)
}

const guestStatus = () => {
    console.log('403 退出')
    const global = useGlobal()
    global.logout()
    disconnect()
    localStorage.removeItem('authorization')
    localStorage.removeItem('AptosWalletName')
    toast.error(i18n.global.t('is_guest_status'))
    window.location.href = '/'
}

//axios的请求事件拦截器对象
http.interceptors.request.use(config => {
    config.baseURL = process.env.VUE_APP_API_HOST
    config.headers['Content-type'] = 'application/json; charset=utf-8'
    config.headers['authorization'] = localStorage.getItem('authorization')
    return config
}, err => {
    errorMessage('Oops', i18n.global.t('err_request'))
    Promise.reject(err)
})

//axios的响应事件拦截器对象
http.interceptors.response.use(res => {
    if (res.data.status.code == 403) {
        console.log('res', res)
        debounce(guestStatus)
        return Promise.reject(res)
    }

    return Promise.resolve(res)
}, err => {
    Promise.reject(err)

    try {
        var data = err.response.data
        var status = err.response.status
        console.log('status code:', status)
        if (status === 403) {
            console.log('err', err)
            debounce(guestStatus)
            return false
        }
        if (data.errors) {
            for (var obj in data.errors) {
                if (data.errors[obj][0]) {
                    errorMessage(status, data.errors[obj][0] || i18n.global.t('err_system'))
                    break
                }
            }
        } else {
            errorMessage(status, data.message || i18n.global.t('err_system'))
        }
    } catch (e) {
        errorMessage('Oops', i18n.global.t('err_system'))
    }
})

http.SUCCESS_CODE = 10200

export default http

// ===================================================================================================
export const preloadResource = (url, onProgress) => {
    return axios({
        method: "get",
        url,
        responseType: "blob",
        onDownloadProgress: function (progressEvent) {
            onProgress(progressEvent.progress)
        },
    })
}
